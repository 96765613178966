<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
    >
      <div class="tablePart">
        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            filterable
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司部门名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="公司部门名称"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';

export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyId: '',
        name: '',
      },
      companyList: [],
      subsidyList: [],
      periodList: [],
      dataList: [],
      dataRule: {
        name: [
          { required: true, message: '公司部门名称不能为空', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getCompanyList();
  },

  methods: {
    init(id, disabled) {
      this.dataList = [];
      this.disabled = disabled;
      this.dataForm.id = id || null;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companydepartment/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.companydepartment };
            }
          });
        }
      });

      this.$init({
        before: () => {
          this.disabled = disabled;
          this.dataForm.id = id || null;
        },
        url: id ? `/tc/companydepartment/info/${id}` : null,
        after: (data) => {
          this.dataForm = { ...data.companydepartment };
        },
      });
    },
    // 表单提交,只提交部分参数
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companydepartment/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              name: this.dataForm.name,
              companyId: this.dataForm.companyId,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },

    getCompanyList() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },
  },
};
</script>
